<template>
  <div>
    {{ $t('message.password') }}
    <div class="flix-html-h1" style="font-family: monospace; margin-top: 0">{{ password }}</div>
  </div>
</template>
<script>
import PasswordGen from './passwordGen.js'
export default {
  components: {},
  props: {
    calendar: [String, Number]
  },
  data () {
    return {
      password: false
    }
  },
  computed: {

  },
  mounted () {
    this.generatePassword()
  },
  methods: {
    generatePassword () {
      var pw = new PasswordGen()
      pw.salt = this.$getUserVariables().user.md5_id
      pw.calendar = this.calendar

      this.password = pw.generatePassword()
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
